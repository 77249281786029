import 'swiper/css';
import 'swiper/css/effect-fade';

import {useCallback, useState} from 'react';
import {Swiper as CoreSwiper} from 'swiper';
import {Autoplay, EffectFade, Navigation} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';

import {Nullable} from '@/utilites/utility-types';

import {ItemProps} from '../../const';
import {Card} from './card/Card';
import styles from './DesktopSlider.module.css';
import {MenuItem} from './menu-item/MenuItem';

type DesktopSliderProps = {
	isLight: boolean;
	list: ItemProps[];
};

export function DesktopSlider({list, isLight}: DesktopSliderProps) {
	const [activeIndex, setActiveIndex] = useState(0);
	const [swiperInstance, setSwiperInstance] = useState<Nullable<CoreSwiper>>(null);

	const onMenuItemClick = useCallback(
		(id: number) => {
			swiperInstance.slideTo(id);
		},
		[swiperInstance],
	);

	const onSliderChange = useCallback(
		(swiper: CoreSwiper) => {
			setActiveIndex(swiper.activeIndex);
		},
		[setActiveIndex],
	);

	return (
		<div className={styles.root}>
			<div className={styles.content}>
				<Swiper
					slidesPerView={1}
					modules={[Navigation, EffectFade, Autoplay]}
					autoplay={{
						delay: 6000,
						waitForTransition: false,
					}}
					direction="horizontal"
					onSwiper={setSwiperInstance}
					onSlideChange={onSliderChange}
					effect="fade"
					fadeEffect={{
						crossFade: true,
					}}
					speed={400}
				>
					{list.map((item) => (
						<SwiperSlide key={item.id}>
							<Card
								title={item.title}
								description={item.text}
								imgLeft={isLight ? item.imgLeftLight : item.imgLeftDark}
								imgRight={isLight ? item.imgRightLight : item.imgRightDark}
							/>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
			<ul className={styles.menu}>
				{list.map((item) => (
					<MenuItem
						key={item.id}
						title={item.title}
						Img={item.img}
						isActive={item.id === activeIndex}
						onClick={() => onMenuItemClick(item.id)}
					/>
				))}
			</ul>
		</div>
	);
}
