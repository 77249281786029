import {ItemProps} from '../../const';
import styles from './InstrumentsCards.module.css';

type InstrumentsCardsProps = {
	list: ItemProps[];
};

export function InstrumentsCards({list}: InstrumentsCardsProps) {
	return (
		<ul className={styles.list}>
			{list.map((item) => {
				const Img = item.img;
				return (
					<li key={item.id} className={styles.item}>
						<div className={styles.head}>
							<div className={styles.title}>{item.title}</div>
							<div className={styles.imgWrapper}>
								<Img className={styles.img} />
							</div>
						</div>
						<div>{item.text}</div>
					</li>
				);
			})}
		</ul>
	);
}
