import {StaticImageData} from 'next/image';
import {VFC} from 'react';

import CalendarIcon from '@/components/common/advanced-header/icons/calendar.svg';
import DiskIcon from '@/components/common/advanced-header/icons/disk.svg';
import DocumentsIcon from '@/components/common/advanced-header/icons/documents.svg';
import MailIcon from '@/components/common/advanced-header/icons/mail.svg';
import MessengerIcon from '@/components/common/advanced-header/icons/messenger.svg';
import TasksIcon from '@/components/common/advanced-header/icons/tasks.svg';
import VideoConferenceIcon from '@/components/common/advanced-header/icons/videoconference.svg';

import CalendarLeftDark from './assets/calendarLeftDark.webp';
import CalendarLeftLight from './assets/calendarLeftLight.webp';
import CalendarRightDark from './assets/calendarRightDark.webp';
import CalendarRightLight from './assets/calendarRightLight.webp';
import DiskLeftDark from './assets/diskLeftDark.webp';
import DiskLeftLight from './assets/diskLeftLight.webp';
import DiskRightDark from './assets/diskRightDark.webp';
import DiskRightLight from './assets/diskRightLight.webp';
import DocumentsLeftDark from './assets/documentsLeftDark.webp';
import DocumentsLeftLight from './assets/documentsLeftLight.webp';
import DocumentsRightDark from './assets/documentsRightDark.webp';
import DocumentsRightLight from './assets/documentsRightLight.webp';
import MailLeftDark from './assets/mailLeftDark.webp';
import MailLeftLight from './assets/mailLeftLight.webp';
import MailRightDark from './assets/mailRightDark.webp';
import MailRightLight from './assets/mailRightLight.webp';
import MessengerLeftDark from './assets/messengerLeftDark.webp';
import MessengerLeftLight from './assets/messengerLeftLight.webp';
import MessengerRightDark from './assets/messengerRightDark.webp';
import MessengerRightLight from './assets/messengerRightLight.webp';
import TasksLeftDark from './assets/tasksLeftDark.webp';
import TasksLeftLight from './assets/tasksLeftLight.webp';
import TasksRightDark from './assets/tasksRightDark.webp';
import TasksRightLight from './assets/tasksRightLight.webp';
import VideoLeftDark from './assets/videoLeftDark.webp';
import VideoLeftLight from './assets/videoLeftLight.webp';
import VideoRightDark from './assets/videoRightDark.webp';
import VideoRightLight from './assets/videoRightLight.webp';

export type ItemProps = {
	id: number;
	title: string;
	text: string;
	img: VFC<React.SVGProps<SVGSVGElement>>;
	imgLeftLight: StaticImageData;
	imgRightLight: StaticImageData;
	imgLeftDark: StaticImageData;
	imgRightDark: StaticImageData;
};

export const data: ItemProps[] = [
	{
		id: 0,
		title: 'Почта',
		text: `Подключайте свой домен, любое количество пользователей
		и\u00A0неограниченный объем почтового ящика`,
		img: MailIcon,
		imgLeftLight: MailLeftLight,
		imgRightLight: MailRightLight,
		imgLeftDark: MailLeftDark,
		imgRightDark: MailRightDark,
	},
	{
		id: 1,
		title: 'Мессенджер',
		text: `Общайтесь в\u00A0чатах и\u00A0каналах, переписывайтесь
		в\u00A0обсуждениях, создавайте папки чатов`,
		img: MessengerIcon,
		imgLeftLight: MessengerLeftLight,
		imgRightLight: MessengerRightLight,
		imgLeftDark: MessengerLeftDark,
		imgRightDark: MessengerRightDark,
	},
	{
		id: 2,
		title: 'Видеоконференции',
		text: `Звоните коллегам без ограничений по\u00A0времени, проводите
		вебинары, приглашайте в\u00A0звонок гостей по\u00A0ссылке`,
		img: VideoConferenceIcon,
		imgLeftLight: VideoLeftLight,
		imgRightLight: VideoRightLight,
		imgLeftDark: VideoLeftDark,
		imgRightDark: VideoRightDark,
	},
	{
		id: 3,
		title: 'Диск',
		text: `Выбирайте любое количество пользователей и\u00A0объем,
		создавайте общие папки, управляйте правами доступа к\u00A0файлам`,
		img: DiskIcon,
		imgLeftLight: DiskLeftLight,
		imgRightLight: DiskRightLight,
		imgLeftDark: DiskLeftDark,
		imgRightDark: DiskRightDark,
	},
	{
		id: 4,
		title: 'Документы',
		text: `Редактируйте документы, таблицы и\u00A0презентации онлайн вместе
		с\u00A0коллегами, отслеживайте изменения в\u00A0файлах, предоставляйте доступ по\u00A0ссылке`,
		img: DocumentsIcon,
		imgLeftLight: DocumentsLeftLight,
		imgRightLight: DocumentsRightLight,
		imgLeftDark: DocumentsLeftDark,
		imgRightDark: DocumentsRightDark,
	},
	{
		id: 5,
		title: 'Календарь',
		text: `Планируйте встречи с\u00A0коллегами, прикрепляйте файлы в\u00A0события,
		делитесь доступом к\u00A0календарю с\u00A0коллегами`,
		img: CalendarIcon,
		imgLeftLight: CalendarLeftLight,
		imgRightLight: CalendarRightLight,
		imgLeftDark: CalendarLeftDark,
		imgRightDark: CalendarRightDark,
	},
	{
		id: 6,
		title: 'Задачи',
		text: `Ставьте таски себе и\u00A0коллегам, назначайте дедлайны
		и\u00A0приоритеты, отслеживайте выполнение задач`,
		img: TasksIcon,
		imgLeftLight: TasksLeftLight,
		imgRightLight: TasksRightLight,
		imgLeftDark: TasksLeftDark,
		imgRightDark: TasksRightDark,
	},
];
