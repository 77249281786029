import classNames from 'classnames';
import {VFC} from 'react';

import styles from './MenuItem.module.css';

type MenuItemProps = {
	title: string;
	Img: VFC<React.SVGProps<SVGSVGElement>>;
	isActive: boolean;
	onClick: () => void;
};

export function MenuItem({title, Img, isActive, onClick}: MenuItemProps) {
	return (
		<li className={classNames(styles.root, {[styles.rootActive]: isActive})} onClick={onClick}>
			<span>{title}</span>
			<div className={styles.imgWrapper}>
				<Img className={styles.img} />
			</div>
		</li>
	);
}
