import classNames from 'classnames';
import {ReactNode, useRef} from 'react';

import {Section, SECTION_STYLES} from '@/components/common/section/Section';
import {Title} from '@/components/common/title/Title';
import {useMediaQueryMatch} from '@/hooks/use-media-query-match';

import {DesktopSlider} from './components/desktop-slider/DesktopSlider';
import {InstrumentsCards} from './components/instruments-cards/InstrumentsCards';
import {data} from './const';
import styles from './Instruments.module.css';

type Instruments = {
	id?: string;
	isLight: boolean;
	title?: string | ReactNode;
	className?: string;
};

export function Instruments({
	id,
	isLight,
	className,
	title = (
		<>
			Используйте все инструменты
			<br />
			для совместной работы сотрудников
		</>
	),
}: Instruments) {
	const titleRef = useRef();
	const [isDesktop] = useMediaQueryMatch('(min-width: 1024px)');
	return (
		<Section
			style={SECTION_STYLES.WITH_BREAKPOINTS}
			className={classNames(styles.root, className)}
			sectionId={id}
			isFullWidth={true}
			classNameFW={classNames(styles.rootFull, {
				[styles.light]: isLight,
				[styles.dark]: !isLight,
			})}
		>
			<Title with1680={false} className={styles.title} ref={titleRef}>
				{title}
			</Title>
			{isDesktop ? <DesktopSlider isLight={isLight} list={data} /> : <InstrumentsCards list={data} />}
		</Section>
	);
}
