import {StaticImageData} from 'next/image';

import Image from '@/components/common/image/Image';

import styles from './Card.module.css';

type CardProps = {
	title: string;
	description: string;
	imgLeft: StaticImageData;
	imgRight: StaticImageData;
};

export function Card({title, description, imgLeft, imgRight}: CardProps) {
	return (
		<div className={styles.root}>
			<div className={styles.left}>
				<Image className={styles.img} src={imgLeft} layout="responsive" alt={title} />
			</div>
			<div className={styles.right}>
				<div className={styles.head}>{title}</div>
				<div className={styles.descr}>{description}</div>
				<div className={styles.imgWrapper}>
					<Image className={styles.img} src={imgRight} layout="responsive" alt={title} />
				</div>
			</div>
		</div>
	);
}
